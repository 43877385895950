import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const Triangle = styled.div`
  position: absolute;
  left: 40px;
  bottom: -30px;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 70px 0 0;
  border-color: #ffffff transparent transparent transparent;
`

const TestiSection = () => (
  <div className='bg-site-indigo-900'>
    <div className='flex flex-col items-center w-11/12  mx-auto pt-16 pb-24 px-4'>
      <h2 className='text-5xl font-display font-bold text-white text-center mb-4'>Testimonials</h2>

      <p className='font-body w-full lg:w-1/2 2xl:w-4/12 text-xl text-center text-white mb-16'>Whether you are a company or a job seeker, HireLive can offer you a way to best utilize your time and efforts in finding your next super star employee or landing a great position to start your new career!</p>

      <div className='max-w-screen-xl grid grid-cols-1 lg:grid-cols-2 gap-24 lg:gap-12 px-0 lg:px-4'>
        <div>
          <div className='relative bg-white p-8 lg:p-10'>
            <q className='text-gray-900 text-lg italic'>I wanted to send you a Big Thank you for such an outstanding open house experience. Due to HireLive excellent marketing design I was able to meet local talent for formal interviews with offers on the spot. Thank you again for an excellent experience I look forward to working with you again in the future.</q>
            <Triangle />
          </div>

          <div className='flex items-center mt-12'>
            <StaticImage
              alt="Dr. Pepper logo"
              src="../../images/sections/dr-pepper.png"
              width={100}
              formats={["auto", "webp", "avif"]}
            />
            <h4 className='text-white text-lg ml-4'><b>Priscilla A., Dr. Pepper Snapple Group</b> <br/> Talent Acquistion Specialist</h4>
          </div>
        </div>
        <div>
          <div className='relative bg-white p-8 lg:p-10'>
            <q className='text-gray-900 text-lg italic'>Your recent email marketing campaign Southern California in late October was a great success for us, we have already made a formal offer to a new sales representative. In addition, we are currently working the database your provided for other candidates that we were unable to spend time with at the event so I know we will be able to generate some additional resources from that effort as well. So far I have been very impressed considering our unemployment rate here locally is one of the highest in the nation. Thank you again for your continued support, I look forward to your next campaign.</q>
            <Triangle />
          </div>

          <div className='flex items-center mt-12'>
            <StaticImage
              alt="ADT Security logo"
              src="../../images/sections/adt-security.jpg"
              width={100}
              formats={["auto", "webp", "avif"]}
            />
            <h4 className='text-white text-lg ml-4'><b>Jason O., ADT Security Services</b> <br/> Small Business Sales Manager</h4>
          </div>
        </div>
        <div>
          <div className='relative bg-white p-8 lg:p-10'>
            <q className='text-gray-900 text-lg italic'>I very much enjoyed the HireLive event here in La Jolla. The staff was very welcoming and informative. After speaking with three companies I ended up landing a great job with Carmax, a company I didn't even plan to interview with. Thanks for your help becoming gainfully employed!</q>
            <Triangle />
          </div>

          <div className='flex items-center mt-12'>
            <StaticImage
              alt="Carmax logo"
              src="../../images/sections/carmax-logo.jpg"
              width={100}
              formats={["auto", "webp", "avif"]}
            />
            <h4 className='text-white text-lg ml-4'><b>Damon F., San Diego, CA</b> <br/> Hired by Carmax</h4>
          </div>
        </div>

        <div>
          <div className='relative bg-white p-8 lg:p-10'>
            <q className='text-gray-900 text-lg italic'>I have been to so many career fairs, and HireLive's was the most organized and professional one I have ever been too. The communication between candidates and HireLive is great with the emails and follow up phone calls.</q>
            <Triangle />
          </div>

          <div className='flex items-center mt-12'>
            <StaticImage
              alt="Carmax logo"
              src="../../images/sections/jpmorganchase-logo.jpg"
              width={100}
              formats={["auto", "webp", "avif"]}
            />
            <h4 className='text-white text-lg ml-4'><b>Doreen G., Orange County CA</b> <br/> Hired by JP Morgan Chase</h4>
          </div>
        </div>

        <div>
          <div className='relative bg-white p-8 lg:p-10'>
            <q className='text-gray-900 text-lg italic'>We have been participating in these events this year and have found them the best use of our Recruiting Dollars (as far as Recruiting Fairs go.) We have the opportunity to interview people on the spot and get resumes from all of the attendees. They are by far the best bang for our buck.</q>
            <Triangle />
          </div>

          <div className='flex items-center mt-12'>
            <StaticImage
              alt="ADT Security logo"
              src="../../images/sections/aflac-logo.jpg"
              width={100}
              formats={["auto", "webp", "avif"]}
            />
            <h4 className='text-white text-lg ml-4'><b>Amy S., AFLAC</b> <br/> State Administrator</h4>
          </div>
        </div>

        <div>
          <div className='relative bg-white p-8 lg:p-10'>
            <q className='text-gray-900 text-lg italic'>The HireLive job fair was the best use of my time! I spent 4 hours at the event, spoke to about 20 candidates and was able to bring back 4 of them to my office for an in depth interview. I hired a Front Desk Manager and a Customer Service Representative. It was the least expensive and quickest way to make quality hires.</q>
            <Triangle />
          </div>

          <div className='flex items-center mt-12'>
            <StaticImage
              alt="ADT Security logo"
              src="../../images/sections/ruff-house-logo.png"
              width={100}
              formats={["auto", "webp", "avif"]}
            />
            <h4 className='text-white text-lg ml-4'><b>Marti M., Ruff House Pet Resort</b> <br/> Owner</h4>
          </div>
        </div>

        <div>
          <div className='relative bg-white p-8 lg:p-10'>
            <q className='text-gray-900 text-lg italic'>I just wanted to thank you for sending this great opportunity. So I got the job and started last week. Thank You! Happy Holiday!</q>
            <Triangle />
          </div>

          <div className='flex items-center mt-12'>
            <StaticImage
              alt="Carmax logo"
              src="../../images/sections/sci-logo.jpeg"
              width={100}
              formats={["auto", "webp", "avif"]}
            />
            <h4 className='text-white text-lg ml-4'><b>Connie S., Shoreline WA</b> <br/> Hired by Service Corporation International</h4>
          </div>
        </div>

        <div>
          <div className='relative bg-white p-8 lg:p-10'>
            <q className='text-gray-900 text-lg italic'>I just wanted to inform you I attended the June 1st Sacramento event and it resulted in getting hired by Aramark. I felt the event was a success for my new career.</q>
            <Triangle />
          </div>

          <div className='flex items-center mt-12'>
            <StaticImage
              alt="Carmax logo"
              src="../../images/sections/aramark-logo.png"
              width={100}
              formats={["auto", "webp", "avif"]}
            />
            <h4 className='text-white text-lg ml-4'><b>Matt P., Sacramento CA</b> <br/> Hired by Aramark</h4>
          </div>
        </div>

        <div>
          <div className='relative bg-white p-8 lg:p-10'>
            <q className='text-gray-900 text-lg italic'>We have had tremendous success working with Hirelive to produce our own Open House Career Fairs. They do a fantastic job of marketing Coverall's sales opportunities and attracting top talent so we can hire only the best. It is a great venue as candidates get to visit the office and meet our entire team. I highly recommend this platform.</q>
            <Triangle />
          </div>

          <div className='flex items-center mt-12'>
            <StaticImage
              alt="ADT Security logo"
              src="../../images/sections/coverall-logo.png"
              width={100}
              formats={["auto", "webp", "avif"]}
            />
            <h4 className='text-white text-lg ml-4'><b>Mona A., Coverall North America</b> <br/> Recruiting Manager</h4>
          </div>
        </div>

        <div>
          <div className='relative bg-white p-8 lg:p-10'>
            <q className='text-gray-900 text-lg italic'>I have been working with my dedicated Account Manager from HireLive for years now. I continue to use HireLive events and services because I can see great sales candidates face to face, fill my open positions at a discounted rate and I am given great customer service by HireLive.</q>
            <Triangle />
          </div>

          <div className='flex items-center mt-12'>
            <StaticImage
              alt="ADT Security logo"
              src="../../images/sections/unilab-logo.png"
              width={100}
              formats={["auto", "webp", "avif"]}
            />
            <h4 className='text-white text-lg ml-4'><b>Dana K., United Laboratories</b> <br/> Vice President of Sales, US</h4>
          </div>
        </div>

        <div>
          <div className='relative bg-white p-8 lg:p-10'>
            <q className='text-gray-900 text-lg italic'>Event went great! I feel it was the best event we have seen for a while. Looks like we may hire 3-4 candidates. I will keep you posted, but just wanted to say that there was some great talent there, and as usual we had a long line, and we were there until almost 3pm conducting short interviews, Crazy! But it's all good.</q>
            <Triangle />
          </div>

          <div className='flex items-center mt-12'>
            <StaticImage
              alt="ADT Security logo"
              src="../../images/sections/sears-logo.png"
              width={100}
              formats={["auto", "webp", "avif"]}
            />
            <h4 className='text-white text-lg ml-4'><b>Eli G., Sears Home Improvement Products</b> <br/> District General Manager</h4>
          </div>
        </div>

        <div>
          <div className='relative bg-white p-8 lg:p-10'>
            <q className='text-gray-900 text-lg italic'>We have 3 candidates already entirely through the process that we will be making offers to before the end of the week! This alone we consider to be a great return on investment, and for us, justifies attending this career fair again in the future. I also have a few other candidates that I am still in the process of having them complete applications and assessments, so hopefully we’ll get a few more. In addition to that, I absolutely love having access to all of the resumes at the end of the fair. For us, being a fairly new brand, a lot of people don’t necessarily stop in to learn more about us. I am able to comb through the stack of attendees, figure out who is qualified for our openings, and then have the chance to call them and sell them on what TMX Finance has to offer!</q>
            <Triangle />
          </div>

          <div className='flex items-center mt-12'>
            <StaticImage
              alt="ADT Security logo"
              src="../../images/sections/tmx-logo.jpeg"
              width={100}
              formats={["auto", "webp", "avif"]}
            />
            <h4 className='text-white text-lg ml-4'><b>Kristen C., TMX Finance</b> <br/> Recruiting Manager</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default TestiSection