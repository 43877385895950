import * as React from "react"

import Layout from "../components/layout/layout"
import TestiSection from "../components/sections/testi-section"
import Seo from "../components/seo"

const TestimonialsPage = () => (
  <Layout>
    <Seo title="Testimonials" />
    <TestiSection />
  </Layout>
)

export default TestimonialsPage
